import {
  AfterContentInit,
  Component,
  ContentChildren,
  inject,
  input,
  QueryList,
  TemplateRef
} from '@angular/core';
import { TableModule } from 'primeng/table';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgTemplateOutlet } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { FileService } from '~madrasa/services/file.service';
import { TemplateDirective } from '~ngx-shared/directives';
import { FileSizePipe } from '~ngx-shared/pipes/filesize.pipe';
import { FileModel } from '~madrasa/models';

@Component({
  selector: 'app-file-table',
  imports: [
    TableModule,
    TranslocoDirective,
    NgTemplateOutlet,
    ButtonModule,
    TooltipModule,
    FileSizePipe
  ],
  templateUrl: './file-table.component.html',
  styleUrl: './file-table.component.scss'
})
export class FileTableComponent implements AfterContentInit {
  readonly fileService = inject(FileService);

  readonly files = input<FileModel[]>([]);
  readonly namespace = input<string | undefined>();

  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>;

  captionTemplate: TemplateRef<any> | null = null;
  actionsTemplate: TemplateRef<any> | null = null;

  ngAfterContentInit(): void {
    this.templates.forEach(tableTemplate => {
      switch (tableTemplate.name) {
        case 'actions':
          this.actionsTemplate = tableTemplate.template;
          break;
        case 'caption':
          this.captionTemplate = tableTemplate.template;
          break;
      }
    });
  }
}
