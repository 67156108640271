<p-table
  stripedRows
  *transloco="let transloco"
  [value]="this.files()"
  responsiveLayout="scroll"
  showGridlines
  scrollHeight="400px"
  class="shadow md:shadow-none">
  @if (this.captionTemplate) {
    <ng-template pTemplate="caption">
      <ng-container [ngTemplateOutlet]="this.captionTemplate"></ng-container>
    </ng-template>
  }

  <ng-template pTemplate="header">
    <tr>
      <th class="w-1/12 text-center">{{ transloco('number') }}</th>
      <th>{{ transloco('name') }}</th>
      <th>{{ transloco('size') }}</th>
      <th class="!text-center">{{ transloco('actions') }}</th>
    </tr>
  </ng-template>

  <ng-template let-file let-rowIndex="rowIndex" pTemplate="body">
    <tr>
      <td class="w-1/12 text-center">{{ rowIndex + 1 }}</td>
      <td>
        <div>{{ file.name }}</div>
      </td>
      <td>{{ file.size | libFilesize: { separator: ',' } }}</td>
      <td class="w-0">
        <div class="flex items-center justify-center space-x-2">
          @if (!file.cannotShow) {
            @let status = this.fileService.status()[file?.id];
            <p-button
              (click)="this.fileService.downloadFile(file?.id, this.namespace())"
              [disabled]="!status?.isOpening && status?.isDownloading"
              [loading]="status?.isOpening || false"
              [pTooltip]="transloco('show')"
              severity="info"
              text
              icon="pi pi-eye"
              tooltipPosition="top">
            </p-button>

            <p-button
              (click)="this.fileService.downloadFile(file?.id, this.namespace(), file?.name)"
              [disabled]="status?.isOpening"
              [loading]="(!status?.isOpening && status?.isDownloading) || false"
              [pTooltip]="transloco('download')"
              text
              severity="info"
              icon="pi pi-download"
              tooltipPosition="top"></p-button>
          }

          @if (this.actionsTemplate) {
            <ng-container
              [ngTemplateOutletContext]="{ $implicit: file }"
              [ngTemplateOutlet]="this.actionsTemplate">
            </ng-container>
          }
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="4">
        {{ transloco('no_data') }}
      </td>
    </tr>
  </ng-template>
</p-table>
